@import '~antd/dist/antd.less';

html{
    font-size:100px;
    height:100%;
}

body{
    margin:0;
    padding:0;
    height:100%;
}

html,body{
    -webkit-text-size-adjust: 100% !important;
}

h1,h2,h3,h4,h5,h6,p,ul{
    margin:0;
    padding:0;
}

*,*::after,*::before{
    box-sizing: border-box;
}

#root,.height{
    height:100%;
}

iframe{
    display: none;
}

input,textarea{
    outline-style: none;
}

li{
    list-style: none;
}

.flex{display:flex;}
.flex-v{flex-direction:column;}
.flex-1{flex:1;min-width:0;}
.flex-x{justify-content:center;}
.flex-y{align-items:center;}
.flex-w{flex-wrap: wrap;}

button{
    outline:none;
}

.loading{
    width:100%;
    height:100%;
    .loading-min{
        width:0.6rem;
        height:0.6rem;
        img{
            width:100%;
            height:100%;
        }
    }
}
@primary-color: #3489fe;